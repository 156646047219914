<template>
  <!-- <el-row type="flex" style="margin: 15px 0;">
    <el-col :span="12">
    </el-col>
    <el-col :span="12" align="right">
      <el-button type="primary" icon="Plus" @click="handleAddDepartment">添加</el-button>
    </el-col>
  </el-row> -->

  <el-table stripe border style="width: 100%"
    :data="filterTableData" v-loading="state.loading">
    <el-table-column label="序号" align="center" type="index" width="60" />
    <el-table-column label="科室" prop="name">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><OfficeBuilding /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.name }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="创建时间">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon><timer /></el-icon>
          <span style="margin-left: 10px">{{ scope.row.create_time }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="状态">
      <template #default="scope">
        <el-switch
          :disabled="limitsDepartment.includes(scope.row.id)"
          style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
          v-model="scope.row.status"
          :active-value="1"
          :inactive-value="0"
          :before-change="beforeChange.bind(this, scope.row)"
        />
      </template>
    </el-table-column>

    <el-table-column label="操作" width="190">
      <template #header>
        <el-input v-model="state.search" size="small" placeholder="请输入科室名" />
      </template>

      <template #default="scope">
        <el-button
          :disabled="limitsDepartment.includes(scope.row.id)"
          size="small"
          type="success"
          @click="handleEdit(scope.row)">
          修改
        </el-button>
      </template>
    </el-table-column>
  </el-table>

  <AddDepartment ref="addDepartmentRef" @finished="finished"></AddDepartment>
</template>

<script setup>
import { reactive, computed, ref } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import AddDepartment from './components/addDepartment.vue'
import { getDepartmentList, departmentUpdate } from '@/api'

const addDepartmentRef = ref()
const limitsDepartment = [1, 2] // 1文电科 2秘书科不可编辑

// 表格数据
const state = reactive({
  loading: false,
  tableData: []
})

const filterTableData = computed(() =>
  state.tableData.filter((data) =>
    !state.search || data.name.toLowerCase().includes(state.search.toLowerCase())
  )
)

// // 添加
// function handleAddDepartment () {
//   console.log('添加科室')
//   addDepartmentRef.value.show()
// }

// 编辑
function handleEdit (item) {
  console.log('编辑', item)
  addDepartmentRef.value.show(item)
}

function finished () {
  getDepartmentListApi()
}

// 开启&关闭
function beforeChange (item) {
  console.log('item', item.status)
  return new Promise((resolve) => {
    const notice = item.status === 0 ? '确认开启当前科室吗？' : '确认关闭当前科室吗？'
    ElMessageBox.confirm(notice).then(() => {
      const params = {
        id: item.id,
        status: item.status === 0 ? 1 : 0
      }
      console.log('params', params)
      departmentUpdateApi(params).then(res => {
        ElMessage({
          type: 'success',
          message: '操作成功'
        })
        return resolve(true)
      })
    }).catch(() => {
      console.log('取消操作')
    })
  })
}

getDepartmentListApi()
function getDepartmentListApi () {
  state.loading = true
  getDepartmentList().then(res => {
    state.tableData = res
    state.loading = false
  })
}

// 科室修改接口
function departmentUpdateApi (params) {
  departmentUpdate(params).then(res => {
    getDepartmentListApi()
    ElMessage({
      type: 'success',
      message: '修改成功'
    })
  })
}
</script>

<style lang="scss" scoped></style>
