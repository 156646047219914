<template>
  <el-dialog v-model="state.showDialog" width="30%">
    <el-form
      ref="ruleFormRef"
      :model="state.ruleForm"
      :rules="rules"
      label-width="52px"
      :size="formSize"
      status-icon>

      <el-form-item label="科室" prop="name">
        <el-input v-model="state.ruleForm.name"  placeholder="请输入科室" />
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="state.ruleForm.status">
          <el-radio :label="0" >关闭</el-radio>
          <el-radio :label="1" >开启</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button v-if="!state.disable" @click="resetForm(ruleFormRef)">重新录入</el-button>
        <el-button type="primary" @click="submitForm(ruleFormRef)">
          {{ state.disable ? '确认修改' : '确认添加' }}
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script setup>
import { ref, reactive, defineExpose, defineEmits } from 'vue'
import { ElMessage } from 'element-plus'
import { departmentCreate, departmentUpdate } from '@/api'
const formSize = ref('default')
const ruleFormRef = ref(null)

const state = reactive({
  showDialog: false,
  ruleForm: {
    name: '', // 科室名称
    status: 1 // 0:关闭 1:开启
  },
  disable: false
})

const rules = {
  name: [
    { required: true, message: '请输入科室', trigger: 'blur' }
  ],
  status: [
    { required: true, message: '请选择状态', trigger: 'change' }
  ]
}

const submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
      if (state.disable) {
        departmentUpdateApi()
      } else {
        departmentCreateApi()
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

const resetForm = (formEl) => {
  if (!formEl) return
  formEl.resetFields()
}

function show (item) {
  state.showDialog = true
  if (item) {
    state.disable = true
    state.ruleForm.id = item.id
    state.ruleForm.name = item.name
    state.ruleForm.status = item.status
  } else {
    state.disable = false
    state.ruleForm = {
      name: '',
      status: 1
    }
  }
}

// 科室创建接口
function departmentCreateApi () {
  departmentCreate(state.ruleForm).then(res => {
    ElMessage({
      type: 'success',
      message: '创建成功'
    })
    state.showDialog = false
    emit('finished')
  })
}

// 科室修改接口
function departmentUpdateApi () {
  const params = {
    id: state.ruleForm.id,
    name: state.ruleForm.name,
    status: state.ruleForm.status
  }
  departmentUpdate(params).then(res => {
    ElMessage({
      type: 'success',
      message: '修改成功'
    })
    state.showDialog = false
    emit('finished')
  })
}

const emit = defineEmits([
  'finished'
])

defineExpose({
  show
})
</script>

<style scoped>
.footer_text {
  margin-right: 20px;
}
</style>
